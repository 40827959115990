import React from "react";
import { useAppContext } from "../context/app-context";
import { usePageTranslation } from "../i18n/translate";

export default function CheckoutPage() {
  const { user } = useAppContext();
  const { t } = usePageTranslation("checkout_page");

  return (
    <div>
      <div className="flex items-center text-2xl font-bold">
        <h1>Checkout</h1>
      </div>
      <div className="font-medium text-center shadow-lg p-5 bg-gray-700 rounded-md mt-10">
        <div className="">
          <h1 className="font-bold text-xl text-center mb-2 text-green-500">
            {t("premium_plan")}
          </h1>
          <p className="md:px-20 text-lg">{t("text_1")}</p>
          <p className="md:px-20 mt-4 text-lg">{t("text_2")}</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 mt-6 gap-4">
          <div className="grid grid-cols-1 bg-gray-800 rounded-lg p-4 gap-4">
            <h1 className="text-xl font-bold border-b border-gray-600 p-4">
              {t("month_plan")}
            </h1>
            <div className="text-lg font-bold">{t("month_price")}</div>
            <ul className="">
              <li>{t("unlimited_dispositive_add")}</li>
              <li>{t("unlimited_command_add")}</li>
              <li>{t("unlimited_executions")}</li>
              <li>{t("month_subscription")}</li>
            </ul>
            <div>
              <a
                href={`https://buy.stripe.com/6oE28Hh1x6QT1OMfZ0?client_reference_id=${user._id}`}
                className="btn btn-success"
              >
                {t("choose")}
              </a>
            </div>
          </div>
          <div className="grid grid-cols-1 bg-gray-800 rounded-lg p-4 gap-4">
            <h1 className="text-xl font-bold border-b border-gray-600 p-4">
              {t("half_yearly_plan")}
            </h1>
            <div className="text-lg font-bold">{t("half_yearly_price")}</div>
            <ul className="">
              <li>{t("unlimited_dispositive_add")}</li>
              <li>{t("unlimited_command_add")}</li>
              <li>{t("unlimited_executions")}</li>
              <li>{t("half_yearly_subscription")}</li>
            </ul>
            <div>
              <a
                href={`https://buy.stripe.com/eVadRpbHd1wz1OM9AB?client_reference_id=${user._id}`}
                className="btn btn-success"
              >
                {t("choose")}
              </a>
            </div>
          </div>
          <div className="grid grid-cols-1 bg-gray-800 rounded-lg p-4 gap-4">
            <h1 className="text-xl font-bold border-b border-gray-600 p-4">
              {t("year_plan")}
            </h1>
            <div className="text-lg font-bold">{t("year_price")}</div>
            <ul className="">
              <li>{t("unlimited_dispositive_add")}</li>
              <li>{t("unlimited_command_add")}</li>
              <li>{t("unlimited_executions")}</li>
              <li>{t("year_subscription")}</li>
            </ul>
            <div>
              <a
                href={`https://buy.stripe.com/8wM4gPaD9grtalicMM?client_reference_id=${user._id}`}
                className="btn btn-success"
              >
                {t("choose")}
              </a>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
