import classNames from "classnames";
import React from "react";
import { Controller } from "react-hook-form";

export default function InputWrapper({
  control,
  name,
  validation,
  text,
  className,
  Component,
  title,
  componentProps,
}) {
  return (
    <Controller
      control={control}
      name={name}
      rules={validation}
      render={({ field, fieldState }) => (
        <div className={classNames("w-full mb-1 flex flex-col", className)}>
          {title && <div className="font-medium mb-1">{title}</div>}
          <div className="w-full">
            <Component {...field} {...componentProps} />
          </div>
          {!fieldState.error && (
            <div>
              <small className="mt-1 text-xs">{text}</small>
            </div>
          )}
          {fieldState.error && (
            <div className="mt-1 text-red-400 text-sm">
              {fieldState.error.message}
            </div>
          )}
        </div>
      )}
    />
  );
}
