import React from "react";
import { useFieldArray } from "react-hook-form";

export const Repeater = ({
  name,
  title,
  control,
  children,
  defaultValue,
  noItemsText,
  addText,
  customButtons,
}) => {
  const { fields, append, remove, update } = useFieldArray({
    name,
    control,
  });

  return (
    <div className="mt-4">
      <div className="font-medium text-lg">{title}</div>
      <div className="mt-2">
        {fields.length > 0 ? (
          <ul className="">
            {fields?.map((field, index) => (
              <li
                key={field.id}
                className="border mb-2 last:mb-0 rounded-md border-gray-600 shadow-lg flex"
              >
                <div className="p-5 flex-grow">
                  {children({
                    field,
                    index,
                    control,
                    withRepeaterName: (fieldName) =>
                      `${name}.${index}.${fieldName}`,
                    remove,
                    update,
                  })}
                </div>
                <div className="p-5 border-l flex items-center border-gray-600 text-right">
                  <button
                    type="button"
                    className="btn btn-sm btn-warning"
                    onClick={() => remove(index)}
                  >
                    <i className="ri-delete-bin-line" />
                  </button>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div className="text-center p-5"> {noItemsText} </div>
        )}

        <div className="pt-5 flex justify-end gap-2">
          <div>{customButtons}</div>
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={() => append({ ...defaultValue })}
          >
            <i className="ri-add-line mr-2" />
            {addText}
          </button>
        </div>
      </div>
    </div>
  );
};
