export const enTranslations = {
  generic: {
    loading: "loading...",
    activation_code: "Activation code",
    license: "LICENSE",
    premium_license: "PREMIUM",
    trial_license: "FREE",
    initial_date: "START",
    until_date: "UNTIL",
    change_license: "Activate PREMIUM",
    exit: "Exit",
    welcome_text_1:
      "Welcome to WOL CMD, our application that lets you remotely turn on your devices and execute commands on your computer using Alexa. With Wake On Lan technology, you can turn on your devices from anywhere in the world, and with Alexa integration, you can do it all using just your voice.",
    welcome_text_2:
      "With our app, you can control your devices without having to get up from the couch or the office. Just set up your devices to use Wake On Lan and connect your Alexa account to start controlling everything using your voice.",
    welcome_text_3:
      "And that's not all! In addition to connecting your devices, our application allows you to execute commands on your computer using Alexa. Want to open a program, play a song or check the weather forecast? Just talk to Alexa and our application will execute the desired command for you.",
    welcome_text_4:
      "With our application, you can customize your settings, create routines, and automate tasks so that everything runs according to your preferences. We are always available to help and are committed to providing the best possible experience for our users.",
    dispositive_type: {
      computer: "Computer",
      game_console: "Console",
      other: "Other",
    },
    command_type: {
      open_program: "Open program",
      custom: "Execute command",
    },
    copied: "Copied!",
    test: "TEST",
    limited: "LIMITED",
  },
  pages: {
    login_page: {
      login_with_amazon: "Login with amazon",
    },
    dispositives_page: {
      dispositives: "Devices",
      add_dispositives: "Add device",
      no_dispositives: "You don't have any devices registered yet",
      name: "Name",
      type: "Type",
      mac_address: "MAC Address",
      path: "Path",
      command: "Path",
      command_name_validation: "The command name field is required",
      command_type_required_validation: "The command type field is required",
      command_path_value_validation: "The program path field is required",
      command_command_value_validation: "The command field is required",
      create_dispositive: "Create device",
      update_dispositive: "Update device",
      name_validation: "The name field is required",
      type_required_validation: "The type field is required",
      mac_required_validation: "The mac address field is required",
      mac_code_validation: "The mac adress field is invalid",
      commands: "Commands",
      no_commands: "You have not yet configured commands for this device",
      remove: "Remove",
      save: "Save",
      remove_ask: "Are you sure?",
      yes: "Yes",
      cancel: "Cancel",
      disabled: "DISABLED",
      premium_plan_list_text: "Activate the PREMIUM plan to use",
      add_command: "add command",
      templates: "Templates",
      import: "import",
      templated_added: "Imported template",
      no_templates_available: "Template not found",
      organize_command_names: "Organize command names?",
      say: "Say",
      command_prefix: "Alexa, turn on",
      or_configure_rotine: "or set up a routine to enable a custom command",
      command_value_hint: "Fill in the values according to your settings",
      path_value_hint:
        "Change the value according to your installation location",
    },
    checkout_page: {
      premium_plan: "PREMIUM PLAN",
      unlimited_dispositive_add: "Unlimited device addition",
      unlimited_command_add: "Unlimited addition of commands",
      unlimited_executions: "Unlimited Executions",
      choose: "Choose",
      text_1:
        "If you are an avid user of technology, you know how Alexa can make your life easier, especially when it comes to executing commands on your computer. With a simple voice command, you can open a browser, play music on Spotify or even turn off your computer. Now imagine being able to have even more control and customization of these commands? This is possible with the premium plan of our application.",
      text_2:
        "By subscribing to the premium plan, you will have access to a wide range of unique features. The biggest advantage is that you will have no limitations in adding new devices and creating commands. This means that you can customize and execute commands for any device connected to your network.",
      month_plan: "MONTHLY",
      month_price: "$1.50/month",
      month_subscription: "Monthly renewal",
      half_yearly_plan: "HALF-YEARLY",
      half_yearly_price: "$6.00 every 6 months",
      half_yearly_subscription: "Half-yearly renewal",
      year_plan: "YEARLY",
      year_price: "$10.00/year",
      year_subscription: "Annual renewal",
    },
    payment_confirmation_page: {
      payment_confirmation: "Payment confirmation",
      confirming_payment: "Confirming payment...",
      confirmed_payment_text:
        "Payment confirmed, you will be redirected in a few seconds.",
    },
    about_page: {
      contact: "Contact",
      name: "Name",
      email: "Email",
      text: "Message",
      send: "send",
      email_sended: "Thank you for your contact!",
    },
  },
  api_errors: {
    generic: "Unknown error",
    invalid_code: "Invalid activation code",
    session_not_found: "Session not found",
    dispositive_not_found: "Device not found",
    user_not_found: "User not found",
    dispositive_already_registered: "Device already exists",
    expired_license: "Expired license",
  },
};
