import classNames from "classnames";
import React from "react";
import { useAppContext } from "../../context/app-context";
import { usePageTranslation } from "../../i18n/translate";

const typeIconMap = {
  COMPUTER: "ri-computer-line",
  GAME_CONSOLE: "ri-gamepad-line",
  OTHER: "ri-file-line",
};

export default function DispositiveList({ dispositives, onSelect, onCreate }) {
  const { user } = useAppContext();
  const { t } = usePageTranslation("dispositives_page");

  return (
    <div className="mt-10">
      <ul className="">
        {dispositives.length === 0 && (
          <div className="font-medium text-center shadow-lg p-5 bg-gray-700 rounded-md">
            <div>{t("no_dispositives")}</div>
            <button
              className="btn btn-primary mt-4"
              onClick={onCreate}
              type="button"
            >
              {t("create_dispositive")}
            </button>
          </div>
        )}
        {dispositives.map((item, index) => (
          <li
            key={item.mac}
            className="p-5 rounded-md shadow-lg border items-center border-gray-600 bg-gray-700 mb-2 last:mb-0 flex relative"
          >
            <div className="grid grid-cols-1 lg:grid-cols-2 flex-grow">
              <div className="flex items-center flex-row">
                <i
                  className={classNames(
                    typeIconMap[item.type],
                    "text-2xl",
                    "mr-2"
                  )}
                />
                <span className="font-semibold">
                  {t("name")}: {item.name}
                </span>
              </div>
              <div className="flex items-center">
                <span className="font-semibold">
                  {t("mac_address")}: {item.mac}{" "}
                </span>
              </div>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-sm btn-warning"
                onClick={() => onSelect(item, index)}
              >
                <i className="ri-file-edit-line"></i>
              </button>
            </div>
            {user.license.trialBlock && index !== 0 && (
              <div className="absolute top-0 left-0 w-full h-full backdrop-blur-sm flex justify-center items-center rounded-md">
                <div className="text-center">
                  <div>
                    <span className="text-warning font-medium">
                      {t("disabled")}
                    </span>
                  </div>
                  <span className="text-sm uppercase">
                    {t("premium_plan_list_text")}
                  </span>
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}
