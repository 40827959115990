import { axiosInstance } from "./axios-instance";

class UserService {
  async login({ email, name, externalId }) {
    return await axiosInstance.post("/login", {
      email,
      name,
      externalId,
    });
  }
  async load() {
    return await axiosInstance.get("/protected/load");
  }
  async addDispositive(dispositive) {
    return await axiosInstance.post("/protected/dispositives", dispositive);
  }
  async updateDispositive(index, dispositive) {
    return await axiosInstance.put(
      `/protected/dispositives/${index}`,
      dispositive
    );
  }

  async removeDispositive({ mac }) {
    return await axiosInstance.delete(`/protected/dispositives/${mac}`);
  }
  async getDispositives() {
    return await axiosInstance.get("/protected/dispositives");
  }
}

export const userService = new UserService();
