import React from "react";
import OnOffLogo from "../../..//assets/onofflogo.png";
import { useAppContext } from "../../../context/app-context";
import { translate } from "../../../i18n/translate";
import { toast } from "react-toastify";

export default function Sidebar() {
  const { user, load } = useAppContext();

  const handleCodeCopy = () => {
    navigator.clipboard.writeText(user.code);
    toast.success(translate("generic.copied"));
  };

  const handleExit = () => {
    localStorage.removeItem("token");
    load();
  };

  return (
    <div className="w-full h-full flex flex-col justify-between">
      {/* UP PART */}
      {/* HEADER */}
      <div>
        <div
          className="hidden lg:flex items-center justify-center cursor-pointer"
          onClick={() => {
            window.location.href = "/";
          }}
        >
          <img src={OnOffLogo} alt="logo" className="w-[50px]"></img>
          <h1 className="font-bold text-xl ml-3">WOL CMD</h1>
        </div>

        <div className="divider"></div>
        {/* LICENSE INFO */}
        <div className="flex items-center justify-center flex-col">
          <i className="ri-bank-card-line text-2xl"></i>
          <div className=" text-center">
            <div className="uppercase">
              <b>{translate("generic.license")}:</b>{" "}
              {user.license.status === "premium" ? (
                <span className="text-success font-bold">
                  {translate("generic.premium_license")}
                </span>
              ) : (
                <span className="text-warning font-bold">
                  {translate("generic.trial_license")}
                  {" - "}{" "}
                  {user.license.trialBlock
                    ? translate("generic.limited")
                    : translate("generic.test")}
                </span>
              )}
            </div>
            <div>
              <b>{translate("generic.initial_date")}:</b>{" "}
              {new Date(user.license.createdAt).toLocaleString()}
            </div>
            <div>
              <b>{translate("generic.until_date")}:</b>{" "}
              {new Date(user.license.until).toLocaleString()}
            </div>
          </div>
        </div>
        {user.license.status !== "premium" && (
          <div className="w-full flex justify-center mt-4">
            <button
              className="btn btn-primary"
              onClick={() => {
                window.location.href = "/checkout";
              }}
            >
              {translate("generic.change_license")}
            </button>
          </div>
        )}
        <div className="divider"></div>
        {/* ACTIVATION CODE */}
        <div className="font-bold uppercase flex flex-col items-center justify-center">
          {translate("generic.activation_code")}
          <div className="flex items-center justify-center mt-2">
            <span className="text-success">{user.code}</span>
            <button
              type="button"
              className="btn btn-success btn-xs ml-2"
              onClick={handleCodeCopy}
            >
              <i className="ri-file-copy-line"></i>
            </button>
          </div>
        </div>
        <div className="divider"></div>
        {/* DOWNLOADS */}
        <div className="hidden lg:block">
          <h2 className="text-center font-bold">DOWNLOADS</h2>
          <div className="flex justify-center mt-4">
            <a
              className="btn btn-primary btn-sm mr-2"
              rel="noreferrer"
              href="https://on-off-inventory.s3.amazonaws.com/installers/WOLCMD-1.0.0+Setup.exe"
              target="_blank"
            >
              <i className="ri ri-windows-line text-xl mr-2"></i>
              Windows
            </a>
            <a
              className="btn btn-primary btn-sm"
              href="https://on-off-inventory.s3.amazonaws.com/installers/wolcmd_1.0.0_amd64.deb"
              rel="noreferrer"
              target="_blank"
            >
              <i className="ri ri-ubuntu-line text-xl mr-2"></i>
              Ubuntu
            </a>
          </div>
          <div className="divider"></div>
        </div>
      </div>
      <div>
        {/* USER INFO */}
        <div className="flex flex-col items-center justify-center text-center mb-5 font-medium">
          <i className="ri-user-3-line text-2xl" />
          <div className="uppercase">{user.name}</div>
          <div className="">{user.email}</div>
          <button
            type="button"
            className="btn btn-sm btn-warning mt-4"
            onClick={handleExit}
          >
            <i className="ri-logout-circle-line mr-2" />
            {translate("generic.exit")}
          </button>
        </div>
      </div>
    </div>
  );
}
