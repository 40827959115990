import React from "react";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import AppContainer from "../components/layout/AppContainer/AppContainer";
import AuthContainer from "../components/layout/AuthContainer";
import { useAppContext } from "../context/app-context";
import CheckoutPage from "../pages/CheckoutPage";
import DispositivePage from "../pages/DispositivesPage/DispositivesPage";
import LoginPage from "../pages/LoginPage";
import { PaymentConfirmationPage } from "../pages/PaymentConfirmationPage";
import AboutPage from "../pages/AboutPage";

const openRoutes = createBrowserRouter([
  {
    path: "/",
    Component: LoginPage,
  },
  {
    path: "/about",
    Component: AboutPage,
  },
  {
    path: "*",
    element: <Navigate to={"/"} />,
  },
]);
const protectedRoutes = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to={"/dispositives"} />,
  },
  {
    path: "/dispositives",
    Component: DispositivePage,
  },
  {
    path: "/checkout",
    Component: CheckoutPage,
  },
  {
    path: "/payment-confirmation",
    Component: PaymentConfirmationPage,
  },
  {
    path: "*",
    element: <Navigate to={"/dispositives"} />,
  },
]);

export default function AppRouter() {
  const { user } = useAppContext();

  return (
    <>
      {user ? (
        <AppContainer>
          <RouterProvider router={protectedRoutes} />
        </AppContainer>
      ) : (
        <AuthContainer>
          <RouterProvider router={openRoutes} />
        </AuthContainer>
      )}
    </>
  );
}
