import axios from "axios";
import { apiErrorHandler } from "../errors/api-error-handler";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use((request) => {
  request.headers["token"] = localStorage.getItem("token");
  return request;
});

axiosInstance.interceptors.response.use(
  (axiosResponse) => {
    return axiosResponse.data;
  },
  (axiosError) => {
    apiErrorHandler(axiosError?.response?.data, {});
    return Promise.reject(axiosError);
  }
);
