/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from "classnames";
import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { translate, usePageTranslation } from "../../i18n/translate";
import { templateService } from "../../services/template.services";
import { BeatLoader } from "react-spinners";

export const TemplatesModal = ({ openned, onClose, getValues, setValue }) => {
  const [os, setOs] = useState("windows");
  const [loading, setLoading] = useState();
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const [search, setSearch] = useState("");
  const [templates, setTemplates] = useState([]);
  const { t } = usePageTranslation("dispositives_page");
  const mountRef = useRef(false);

  const handleApply = (item) => {
    const commands = getValues("commands");
    setValue("commands", [
      ...(commands ?? []),
      { name: item.name, value: item.template.value, type: item.template.type },
    ]);
    toast.success(t("templated_added"));
    onClose();
  };

  const load = useCallback(async (os, search) => {
    setImagesLoaded(0);
    setLoading(true);
    const templates = await templateService.get(os, search);
    setTemplates(templates);
    setLoading(false);
  }, []);

  const debounceRef = useRef(debounce(load, 500));

  useEffect(() => {
    if (openned) {
      mountRef.current = false;
    } else {
      setSearch("");
      setOs("windows");
    }
  }, [openned]);

  useEffect(() => {
    if (openned) {
      load(os, search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [os, openned]);

  useEffect(() => {
    if (openned) {
      if (mountRef.current) {
        debounceRef.current(os, search);
      } else {
        mountRef.current = true;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, openned]);

  const handleImageLoad = () => {
    setImagesLoaded((quantity) => quantity + 1);
    console.log("carregou");
  };

  return (
    <>
      <div
        className={classNames(
          "modal modal-bottom md:modal-middle",
          openned && "modal-open"
        )}
      >
        <div className="modal-box lg:w-6/12">
          <div className="flex justify-between">
            <h3 className="font-bold text-lg flex items-center">
              <i className="ri-download-line mr-2" />
              <span className="uppercase">{t("templates")}</span>
            </h3>
            <button
              className="btn btn-sm btn-circle btn-primary"
              type="button"
              onClick={onClose}
            >
              <i className="ri-close-line" />
            </button>
          </div>
          <div className="py-4">
            <div className="w-full my-2 relative flex items-center">
              <input
                className="input input-primary w-full pr-[40px]"
                placeholder="ex: Fortinte"
                value={search}
                onChange={(event) => setSearch(event.target.value)}
              />
              <i className="absolute ri-search-line text-lg right-3"> </i>
            </div>
            <div className="tabs">
              <a
                className={classNames(
                  "tab tab-lg tab-bordered",
                  os === "windows" && "tab-active"
                )}
                onClick={() => setOs("windows")}
              >
                <i className={classNames("ri-windows-line mr-2")} /> Windows
              </a>
              <a
                className={classNames(
                  "tab tab-lg tab-bordered",
                  os === "ubuntu" && "tab-active"
                )}
                onClick={() => setOs("ubuntu")}
              >
                <i className={classNames("ri-ubuntu-line mr-2")} /> Ubuntu
              </a>
            </div>
            <div className="mt-2">
              {!loading && !templates?.length && (
                <div className="text-center py-4">
                  {t("no_templates_available")}
                </div>
              )}

              {(loading || imagesLoaded < templates.length) && (
                <div className="w-full p-5 flex justify-center items-center">
                  <BeatLoader color="#36d7b7" size={15} />
                </div>
              )}

              {!loading && templates?.length > 0 && (
                <div
                  className={classNames(
                    "grid grid-cols-2 md:grid-cols-5 gap-2 py-2",
                    imagesLoaded < templates.length && "hidden"
                  )}
                >
                  {templates.map((item, index) => (
                    <div
                      key={index}
                      className="bg-gray-700 flex flex-col rounded-md p-2 justify-between items-center cursor-pointer hover:bg-gray-600 transition-all"
                      onClick={() => handleApply(item)}
                    >
                      <img
                        src={item.image}
                        alt="item-logo"
                        onLoad={() => handleImageLoad()}
                        className="max-h-[70px] mb-1"
                      />
                      <div className="text-center">
                        <h2 className="font-medium">{item.name}</h2>
                        <span className="text-xs uppercase">
                          {translate(
                            `generic.command_type.${item.template.type}`
                          )}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
