import React, { useEffect, useState } from "react";
import { hideLoader, showLoader } from "../../components/loaders/FullLoader";
import { userService } from "../../services/user.service";
import DispositiveList from "./DispositiveList";
import DispositiveForm from "./DispositivesForm";
import { usePageTranslation } from "../../i18n/translate";

export default function DispositivePage() {
  const [dispositives, setDispositives] = useState([]);
  const [mode, setMode] = useState();
  const [selected, setSelected] = useState();

  const { t } = usePageTranslation("dispositives_page");

  const loadDispositives = async () => {
    showLoader();
    const dispositives = await userService.getDispositives();
    setMode(null);
    setSelected(null);
    setDispositives(dispositives);
    hideLoader();
  };

  useEffect(() => {
    loadDispositives();
  }, []);

  return (
    <div>
      <div className="flex flex-col lg:flex-row justify-center lg:justify-between items-center text-2xl font-bold">
        <div className="flex mb-4 lg:mb-0">
          <h1>{t("dispositives")}</h1>
        </div>
        {(selected || mode) && (
          <button
            onClick={loadDispositives}
            type="button"
            className="btn btn-primary btn-sm"
          >
            <i className="ri-arrow-left-line" />
          </button>
        )}
        {!(selected || mode) && (
          <button
            onClick={() => setMode("creating")}
            type="button"
            className="btn btn-primary btn-sm"
          >
            <i className="ri-add-line mr-2" />
            {t("add_dispositives")}
          </button>
        )}
      </div>
      {selected || mode ? (
        <DispositiveForm
          item={selected?.item}
          index={selected?.index}
          dispositives={dispositives}
          onChange={loadDispositives}
        />
      ) : (
        <DispositiveList
          onCreate={() => setMode("creating")}
          dispositives={dispositives}
          onSelect={(item, index) => setSelected({ index, item })}
        />
      )}
    </div>
  );
}
