import React, { useEffect, useRef, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import Swal from "sweetalert2";
import { PosFixInput } from "../../components/form/PostFixInput";
import { SelectInput } from "../../components/form/SelectInput";
import { TextInput } from "../../components/form/TextInput";
import InputWrapper from "../../components/form/shared/InputWrapper";
import { Repeater } from "../../components/form/shared/Repeater";
import { showLoader } from "../../components/loaders/FullLoader";
import { translate, usePageTranslation } from "../../i18n/translate";
import { userService } from "../../services/user.service";
import { TemplatesModal } from "./TemplatesModal";
import { CheckboxInput } from "../../components/form/CheckboxInput";

const deviceTypes = [
  {
    value: "GAME_CONSOLE",
    text: translate("generic.dispositive_type.game_console"),
  },
  {
    value: "COMPUTER",
    text: translate("generic.dispositive_type.computer"),
  },
  {
    value: "OTHER",
    text: translate("generic.dispositive_type.other"),
  },
];

export const commandTypes = [
  {
    value: "open_program",
    text: translate("generic.command_type.open_program"),
  },
  {
    value: "custom",
    text: translate("generic.command_type.custom"),
  },
];

const CommandItem = ({ withRepeaterName, control, setValue }) => {
  const { t } = usePageTranslation("dispositives_page");
  const name = useWatch({ name: withRepeaterName("name"), control });
  const type = useWatch({ name: withRepeaterName("type"), control });
  const organizeCommandNames = useWatch({
    name: "organizeCommandNames",
    control,
  });
  const dispositiveName = useWatch({ name: "name", control });
  const ref = useRef(type);

  useEffect(() => {
    if (type !== ref.current) {
      setValue(withRepeaterName("value"), "");
      ref.current = type;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, setValue]);

  return (
    <div className="">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-2">
        {organizeCommandNames ? (
          <InputWrapper
            title={t("name")}
            name={withRepeaterName("name")}
            control={control}
            Component={PosFixInput}
            className="col-span-2"
            componentProps={{
              placeholder: "Spotify",
              posFix: `- ${dispositiveName}`,
            }}
            validation={{
              required: t("command_name_validation"),
            }}
          />
        ) : (
          <InputWrapper
            title={t("name")}
            name={withRepeaterName("name")}
            control={control}
            Component={TextInput}
            className="col-span-2"
            componentProps={{
              placeholder: "Spotify",
            }}
            validation={{
              required: t("command_name_validation"),
            }}
          />
        )}

        <InputWrapper
          title={t("type")}
          name={withRepeaterName("type")}
          control={control}
          Component={SelectInput}
          componentProps={{
            options: commandTypes,
            firstOption: "Escolha o tipo",
          }}
          validation={{
            required: t("command_type_required_validation"),
          }}
        />
      </div>
      <div>
        {type === "open_program" ? (
          <InputWrapper
            title={t("path")}
            name={withRepeaterName("value")}
            control={control}
            Component={TextInput}
            className=""
            text={t('path_value_hint')}
            componentProps={{
              placeholder:
                "C:Users\\[yourusername]\\AppData\\Roaming\\Spotify\\Spotify.exe",
            }}
            validation={{
              required: t("command_path_value_validation"),
            }}
          />
        ) : (
          <InputWrapper
            title={t("command")}
            name={withRepeaterName("value")}
            control={control}
            Component={TextInput}
            className=""
            text={t('command_value_hint')}
            componentProps={{ placeholder: 'echo "Hello world"' }}
            validation={{
              required: t("command_command_value_validation"),
            }}
          />
        )}
      </div>
      {name && (
        <div className="w-full mt-2 bg-slate-800 rounded-md p-2 text-xs text-center">
          {t("say") +
            ' "' +
            t("command_prefix") +
            " " +
            name +
            '" ' +
            t("or_configure_rotine")}{" "}
        </div>
      )}
    </div>
  );
};

export default function DispositiveForm({ index, item, onChange }) {
  const form = useForm({
    values: item
      ? {
          ...item,
          dispositives: item.dispositives ?? [],
          type: item.type ?? "COMPUTER",
        }
      : {
          type: "COMPUTER",
          commands: [],
          name: "",
          mac: "",
          organizeCommandNames: true,
        },
  });

  const type = form.watch("type");

  const { t } = usePageTranslation("dispositives_page");
  const [templateOpenned, setTemplateOpenned] = useState(false);

  const onSubmit = async (data) => {
    try {
      if (item) {
        await userService.updateDispositive(index, {
          ...data,
          mac: data.mac.trim().replace(/[:]/g, "-").toUpperCase(),
        });
      } else {
        await userService.addDispositive({
          ...data,
          mac: data.mac.trim().replace(/[:]/g, "-").toUpperCase(),
        });
      }
      onChange();
    } catch (error) {}
  };

  const onDelete = async () => {
    const result = await Swal.fire({
      title: t("remove"),
      text: t("remove_ask"),
      icon: "warning",
      confirmButtonText: t("yes"),
      cancelButtonText: t("cancel"),
      showCancelButton: true,
      reverseButtons: true,
    });
    if (result.isConfirmed) {
      showLoader();
      await userService.removeDispositive({ mac: item.mac });
      onChange();
    }
  };

  useEffect(() => {
    if (type !== "COMPUTER") {
      form.setValue("commands", []);
    }
  }, [type, form]);

  return (
    <div className="rounded-md shadow-lg mt-10 bg-gray-700 ">
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="mb-2 p-5 border-gray-600 border-b">
          <span className="font-semibold">
            {item ? t("update_dispositive") : t("create_dispositive")}
          </span>
        </div>
        <div className="p-5">
          <div className="grid grid-cols-1 gap-2">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-2">
              <InputWrapper
                title={t("name")}
                name="name"
                control={form.control}
                Component={TextInput}
                className="col-span-2"
                componentProps={{ placeholder: "Computador escritório" }}
                validation={{
                  required: t("name_validation"),
                }}
              />
              <InputWrapper
                title={t("type")}
                name="type"
                control={form.control}
                Component={SelectInput}
                componentProps={{
                  options: deviceTypes,
                  firstOption: "Escolha o tipo",
                }}
                validation={{
                  required: t("type_required_validation"),
                }}
              />
            </div>

            <InputWrapper
              title={t("mac_address")}
              name="mac"
              control={form.control}
              Component={TextInput}
              componentProps={{ placeholder: "00-00-00-00-00-00" }}
              validation={{
                required: t("mac_required_validation"),
                pattern: {
                  value: /^([0-9A-F]{2}[:-]){5}([0-9A-F]{2})$/,
                  message: t("mac_code_validation"),
                },
              }}
            />
            <InputWrapper
              title={t("organize_command_names")}
              name="organizeCommandNames"
              control={form.control}
              Component={CheckboxInput}
              className="col-span-1"
              validation={{
                required: t("name_validation"),
              }}
            />
          </div>
          {type === "COMPUTER" && (
            <div>
              <Repeater
                name="commands"
                control={form.control}
                title={t("commands")}
                noItemsText={t("no_commands")}
                addText={t("add_command")}
                defaultValue={{ type: "open_program", name: "", value: "" }}
                customButtons={
                  <button
                    className="btn btn-primary btn-sm"
                    type="button"
                    onClick={() => setTemplateOpenned(!templateOpenned)}
                  >
                    <i className="ri-download-line mr-2"></i>
                    {t("import")}
                  </button>
                }
              >
                {({ withRepeaterName, control, field }) => (
                  <CommandItem
                    key={field.id}
                    withRepeaterName={withRepeaterName}
                    control={control}
                    getValues={form.getValues}
                    setValue={form.setValue}
                  />
                )}
              </Repeater>
            </div>
          )}
        </div>
        <div className="flex justify-end border-t border-gray-600 p-5">
          {item && (
            <button
              type="button"
              className="btn btn-warning mr-2"
              onClick={onDelete}
            >
              <i className="ri-delete-bin-line mr-2" />
              {t("remove")}
            </button>
          )}
          <button className="btn btn-primary">
            <i className="ri-save-line mr-2" />
            {t("save")}
          </button>
        </div>
      </form>
      <TemplatesModal
        openned={templateOpenned}
        onClose={() => setTemplateOpenned(false)}
        getValues={form.getValues}
        setValue={form.setValue}
      />
    </div>
  );
}
