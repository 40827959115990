import { axiosInstance } from "./axios-instance";

class TemplateService {
  async get(os, search) {
    return await axiosInstance.get(
      `/protected/templates?os=${os}&search=${search}`
    );
  }
}

export const templateService = new TemplateService();
