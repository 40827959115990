import React from "react";
import OnOffLogo from "../assets/onofflogo.png";
import { translate } from "../i18n/translate";

export default function LoadingPage() {
  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <div className="flex flex-col items-center">
        <img src={OnOffLogo} alt="logo" className="w-[125px] mb-2"></img>
        <span className="font-medium">{translate("generic.loading")}...</span>
      </div>
    </div>
  );
}
