import React from "react";
import { useAppContext } from "../context/app-context";
import { usePageTranslation } from "../i18n/translate";

export default function LoginPage() {
  const { login } = useAppContext();

  const {t} = usePageTranslation('login_page');

  const handleAmazonLogin = () => {
    let options = {};
    options.scope = "profile";
    options.pkce = true;
    window.amazon.Login.authorize(options, function (response) {
      if (response.error) {
        alert("oauth error " + response.error);
        return;
      }
      window.amazon.Login.retrieveToken(response.code, function (response) {
        if (response.error) {
          alert("oauth error " + response.error);
          return;
        }
        window.amazon.Login.retrieveProfile(
          response.access_token,
          function (response) {
            login({
              name: response.profile.Name,
              email: response.profile.PrimaryEmail,
              externalId: response.profile.CustomerId,
            });
          }
        );
      });
    });
  };

  return (
    <div>
      <h1 className="hidden text-gray-600 text-2xl text-center mb-5 font-medium lg:block">
        LOGIN
      </h1>
      <div className="lg:mt-5 grid grid-cols-1 gap-2">
        <button
          id="LoginWithAmazon"
          onClick={handleAmazonLogin}
          className="btn btn-warning"
        >
          <i className="ri-amazon-line text-lg" />
          <span className="ml-2">{t('login_with_amazon')}</span>
        </button>
      </div>
    </div>
  );
}
