import React from "react";
import classNames from "classnames";

export const CheckboxInput = React.forwardRef((props, ref) => {
  return (
    <input
      {...props}
      ref={ref}
      type="checkbox"
      checked={props.value}
      className={classNames("checkbox checkbox-primary", props.className)}
    />
  );
});
