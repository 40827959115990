export const ptTranslations = {
  generic: {
    loading: "carregando...",
    activation_code: "Código de ativação",
    license: "LICENÇA",
    premium_license: "PREMIUM",
    trial_license: "GRÁTIS",
    initial_date: "INICIO",
    until_date: "VALIDO ATÉ",
    change_license: "Ativar premium",
    exit: "Sair",
    welcome_text_1:
      "Seja bem-vindo ao WOL CMD, nossa aplicação que permite ligar seus dispositivos remotamente e executar comandos no seu computador usando a Alexa. Com a tecnologia Wake On Lan, você pode ligar seus dispositivos de qualquer lugar do mundo e, com a integração da Alexa, você pode fazer tudo isso usando apenas a sua voz.",
    welcome_text_2:
      "Com nossa aplicação, você pode controlar seus dispositivos sem precisar se levantar do sofá ou do escritório. Basta configurar seus dispositivos para usar o Wake On Lan e conectar sua conta da Alexa para começar a controlar tudo usando sua voz.",
    welcome_text_3:
      "E não é só isso! Além de ligar seus dispositivos, nossa aplicação permite que você execute comandos no seu computador usando a Alexa. Quer abrir um programa, reproduzir uma música ou verificar a previsão do tempo? Basta falar com a Alexa e a nossa aplicação irá executar o comando desejado para você.",
    welcome_text_4:
      "Com nossa aplicação, você pode personalizar suas configurações, criar rotinas e automatizar tarefas para que tudo seja executado de acordo com suas preferências. Estamos sempre disponíveis para ajudar e estamos comprometidos em fornecer a melhor experiência possível para nossos usuários.",
    dispositive_type: {
      computer: "Computador",
      game_console: "Console",
      other: "Outro",
    },
    command_type: {
      open_program: "Abrir programa",
      custom: "Executar comando",
    },
    copied: "Copiado!",
    test: "TESTE",
    limited: "LIMIITADO",
  },
  pages: {
    login_page: {
      login_with_amazon: "Entrar com a amazon",
    },
    dispositives_page: {
      dispositives: "DISPOSITIVOS",
      add_dispositives: "Adicionar dispositivos",
      no_dispositives: "Você ainda não tem dispositivos cadastrados",
      name: "Nome",
      type: "Tipo",
      mac_address: "Endereço MAC",
      path: "Caminho",
      command: "Caminho",
      command_name_validation: "O nome do comando é obrigatório",
      command_type_required_validation: "O tipo do comando é obrigatório",
      command_path_value_validation: "O caminho do programa é obrigatório",
      command_command_value_validation: "O comando é obrigatório",
      create_dispositive: "Criar dispositivo",
      update_dispositive: "Atualizar dispositivo",
      name_validation: "O nome é obrigatório",
      type_required_validation: "O tipo é obrigatório",
      mac_required_validation: "O endereço mac é obrigatório",
      mac_code_validation: "O endereço mac é inválido",
      commands: "Comandos",
      no_commands: "Você ainda não configurou comandos para este dispositivo",
      remove: "Remover",
      save: "Salvar",
      remove_ask: "Tem certeza que deseja continuar?",
      yes: "Sim",
      cancel: "Cancelar",
      disabled: "DESABILITADO",
      premium_plan_list_text: "Ative o plano PREMIUM para reabilitar",
      add_command: "Adicionar comando",
      templates: "Templates",
      import: "importar",
      templated_added: "Template importado",
      no_templates_available: "Template não econtrado",
      organize_command_names: "Organizar nomes de comandos?",
      say: "Diga",
      command_prefix: "Alexa, ligar",
      or_configure_rotine:
        "ou configure uma rotina para habilitar uma invocação customizada",
      command_value_hint:
        "Preencha os valores de acordo com suas configurações",
      path_value_hint: "Altere o valor de acordo com seu local de instalação",
    },
    checkout_page: {
      premium_plan: "PLANO PREMIUM",
      unlimited_dispositive_add: "Adição ilimitada de dispositivos",
      unlimited_command_add: "Adição ilimitada de comandos",
      unlimited_executions: "Execuções ilimitadas",
      choose: "Escolher",
      text_1:
        "Se você é um usuário ávido de tecnologia, sabe como a Alexa pode tornar sua vida mais fácil, principalmente quando se trata de executar comandos no seu computador. Com um simples comando de voz, você pode abrir um navegador, tocar música no Spotify ou até mesmo desligar o computador. Agora, imagine poder ter ainda mais controle e personalização desses comandos? Isso é possível com o plano premium do nosso aplicativo.",
      text_2:
        "Ao se inscrever no plano premium, você terá acesso a uma ampla gama de recursos exclusivos. A maior vantagem é que você não terá limitações na adição de novos dispositivos e criação de comandos. Isso significa que você pode personalizar e executar comandos para qualquer dispositivo conectado à sua rede.",
      month_plan: "MENSAL",
      month_price: "R$4,00/mês",
      month_subscription: "Renovação mensal",
      half_yearly_plan: "SEMESTRAL",
      half_yearly_price: "R$17,00/semestre",
      half_yearly_subscription: "Renovação semestral",
      year_plan: "ANUAL",
      year_price: "R$30,00/ano",
      year_subscription: "Renovação anual",
    },
    payment_confirmation_page: {
      payment_confirmation: "Confirmação de pgamento",
      confirming_payment: "Confirmando pagamento...",
      confirmed_payment_text:
        "Pagamento confirmado, você será redirecionado em poucos segundo.",
    },
    about_page: {
      contact: "Contato",
      name: "Nome",
      email: "Email",
      text: "Mensagem",
      send: "enviar",
      email_sended: "Obrigado pelo seu contato!",
    },
  },
  api_errors: {
    generic: "Erro desconhecido",
    invalid_code: "Código inválido",
    session_not_found: "Sessão não encontrada",
    dispositive_not_found: "Dispositivo não encontrado",
    user_not_found: "Usuário não encontrado",
    dispositive_already_registered: "Dispositivo ja existe",
    expired_license: "Licensa expirada",
  },
};
