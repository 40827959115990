import { ToastContainer } from "react-toastify";
import { AppContextProvider } from "./context/app-context";
import AppRouter from "./routes/AppRouter";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <AppContextProvider>
      <AppRouter />
      <ToastContainer position="top-center" theme="colored" />
    </AppContextProvider>
  );
}

export default App;
