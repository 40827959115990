import { useCallback } from "react";
import { enTranslations } from "./translations/en";
import { ptTranslations } from "./translations/pt";
import get from "lodash.get";

const navigatorLanguage = navigator.language.split("-")[0];
const acceptedLanguages = ["pt", "en"];

const selectedLanguage = acceptedLanguages.includes(navigatorLanguage)
  ? navigatorLanguage
  : "en";

const tranlsations =
  selectedLanguage === "pt" ? ptTranslations : enTranslations;

export const translate = (code, source = tranlsations) => {
  return get(source, code) ?? code;
};

export const usePageTranslation = (page) => {
  const pageTranslations = tranlsations.pages[page];

  // eslint-disable-next-line no-unused-vars
  const t = useCallback((code) => {
    return translate(code, pageTranslations);
  }, [pageTranslations]);

  return {
    t: (code) => translate(code, pageTranslations),
  };
};
