import React from "react";
import onOffLogo from "../../../assets/onofflogo.png";

export default function Header({ setMenu }) {
  return (
    <div className="p-5 flex justify-between">
      <div className="flex items-center">
        <img src={onOffLogo} alt="logo" className="w-[35px]"></img>
        <h1 className="font-bold text-xl ml-2">WOL CMD</h1>
      </div>
      <button
        type="button"
        className="btn btn-sm btn-primary"
        onClick={() => setMenu((menu) => !menu)}
      >
        <i className="ri ri-menu-line"></i>
      </button>
    </div>
  );
}
