import { toast } from "react-toastify";
import { translate } from "../i18n/translate";

export const apiErrorHandler = (error) => {
  if (error?.fromApi) {
    const message = translate(`api_errors.${error.code}`);
    if (message === error.code) {
      toast.error(translate(`api_errors.generic`));
    }
    toast.error(translate(message));
  } else {
    toast.error(translate(`api_errors.generic`));
  }
};
