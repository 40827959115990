import { useEffect, useState } from "react";
import logo from "../../assets/onofflogo.png";
import { translate } from "../../i18n/translate";

let showCallback, hideCallback;

export const showLoader = () => {
  showCallback && showCallback();
};

export const hideLoader = () => {
  hideCallback && hideCallback();
};

export const FullLoader = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    let showTime, timeoutRef;
    showCallback = () => {
      showTime = Date.now();
      clearInterval(timeoutRef)
      setShow(true);
    };
    hideCallback = () => {
      let elapsed = Date.now() - (showTime ?? 0);
      let remaining = 1000 - elapsed;
      if (remaining <= 0) {
        clearTimeout(timeoutRef);
        setShow(false);
      } else {
        setTimeout(() => {
          clearTimeout(timeoutRef);
          setShow(false);
        }, remaining);
      }
    };
  }, []);

  return (
    show && (
      <div className="absolute backdrop-blur-lg h-full w-full top-0 left-0">
        <div className="flex flex-col w-full h-full justify-center items-center">
          <div>
            <img
              src={logo}
              alt="logo"
              className="animate-bounce ease-in h-[100px]"
            ></img>
          </div>
          <div>{translate("generic.loading")}</div>
        </div>
      </div>
    )
  );
};
