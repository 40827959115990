import React from "react";
import classNames from "classnames";

export const SelectInput = React.forwardRef(
  ({ firstOption, ...props }, ref) => {
    return (
      <select
        {...props}
        ref={ref}
        className={classNames("select w-full", props.className)}
      >
        {firstOption && (
          <option key="disabled" disabled>
            {firstOption}
          </option>
        )}

        {props.options &&
          props.options.map((item) => (
            <option key={item.value} value={item.value} text={item.text}>
              {item.text}
            </option>
          ))}
      </select>
    );
  }
);
