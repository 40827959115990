import emailJs from "@emailjs/browser";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { TextArea } from "../components/form/TextAreaInput";
import { TextInput } from "../components/form/TextInput";
import InputWrapper from "../components/form/shared/InputWrapper";
import { usePageTranslation } from "../i18n/translate";

export default function AboutPage() {
  const { t } = usePageTranslation("about_page");
  const [sended, setSended] = useState(false);
  const form = useForm();

  const handleSubmit = async (data) => {
    await emailJs.send(
      "service_oxfnyti",
      "template_r69oy6x",
      {
        from_name: data.name,
        email: data.email,
        message: data.text,
      },
      "_-gkMfgcms-1ea56e"
    );
    setSended(true);
  };

  return (
    <div>
      <div className="font-medium shadow-lg p-5 bg-gray-700 rounded-md mt-10">
        <h1 className="text-2xl mb-4">{t("contact")}</h1>
        {sended ? (
          <div className="flex justify-center items-center">
            <i className="text-2xl ri-checkbox-circle-line mr-2 text-green-500" />
            {t("email_sended")}
          </div>
        ) : (
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <div className="grid grid-cols-1 gap-2">
              <InputWrapper
                title={t("name")}
                control={form.control}
                name="name"
                Component={TextInput}
                validation={{
                  required: t("required_validation"),
                }}
              />
              <InputWrapper
                title={t("email")}
                control={form.control}
                name="email"
                Component={TextInput}
                validation={{
                  required: t("required_validation"),
                }}
              />
              <InputWrapper
                title={t("text")}
                control={form.control}
                name="text"
                Component={TextArea}
                componentProps={{ rows: 4 }}
                validation={{
                  required: t("required_validation"),
                }}
              />
              <button className="btn btn-success">{t("send")}</button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}
