import React from "react";
import classNames from "classnames";

export const TextInput = React.forwardRef((props, ref) => {
  return (
    <input
      {...props}
      ref={ref}
      className={classNames("input input-primary placeholder-gray-600 w-full", props.className)}
    />
  );
});
