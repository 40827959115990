import React from "react";
import Sidebar from "./Sidebar";
import Logo from "../../../assets/logo.png";
import { FullLoader } from "../../loaders/FullLoader";
import Header from "./Header";
import { useState } from "react";
import classNames from "classnames";

export default function AppContainer({ children }) {
  const [menu, setMenu] = useState(false);

  return (
    <div className="w-screen h-screen relative">
      <div className="h-[70px] lg:h-0 bg-gray-700">
        <Header setMenu={setMenu}></Header>
      </div>
      <div className="w-screen flex-col lg:flex-row flex h-[calc(100vh-40px)]">
        <div
          className={classNames(
            " lg:h-auto w-full lg:w-[420px] lg:p-5 flex flex-col  bg-gray-700 shadow-lg",
            !menu ? "h-0 overflow-hidden" : "pb-5"
          )}
        >
          <Sidebar />
        </div>
        <div className="p-5 lg:px-10 lg:max-h-[calc(100vh-40px)] overflow-y-scroll w-full bg-gray-800 relative">
          {children}
          <FullLoader />
        </div>
      </div>
      <div className="w-full h-[40px] bg-gray-300 flex justify-center px-5 items-center">
        <span className="text-gray-600">
          <b>Laera Software</b>
        </span>
        <img className="w-[15px] ml-2" src={Logo} alt="logo" />
        <span className="text-gray-600 ml-5">
          2017-{new Date().getFullYear()}
        </span>
      </div>
    </div>
  );
}
