import React, { useCallback, useEffect, useState } from "react";
import LoadingPage from "../pages/LoadingPage";
import { userService } from "../services/user.service";

export const appContext = React.createContext({});
export const useAppContext = () => React.useContext(appContext);

export const AppContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();

  const load = useCallback(async () => {
    setLoading(true);
    const tokenExists = localStorage.getItem("token");
    if (tokenExists) {
      try {
        const user = await userService.load();
        setUser(user);
      } catch (error) {
        localStorage.removeItem("token");
        setUser(undefined)
      }
    }else {
      localStorage.removeItem("token");
      setUser(undefined)
    }
    setLoading(false);
  }, []);

  const login = useCallback(async ({ name, email, externalId }) => {
    setLoading(true);
    try {
      const response = await userService.login({ name, email, externalId });
      localStorage.setItem("token", response.token);
      load();
    } catch (error) {
      console.error("authentication error");
      setLoading(false);
    }
  }, [load]);

  useEffect(() => {
    load();
  }, [load]);

  return (
    <appContext.Provider value={{ loading, user, login, load }}>
      {loading ? <LoadingPage /> : children}
    </appContext.Provider>
  );
};
