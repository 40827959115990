import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import { apiErrorHandler } from "../errors/api-error-handler";
import { paymentService } from "../services/payment.service";
import { usePageTranslation } from "../i18n/translate";

export const PaymentConfirmationPage = () => {
  const [loading, setLoading] = useState();
  const navigate = useNavigate();
  const [paymentStatus, setPaymentStatus] = useState();
  const [searchParameters] = useSearchParams();
  const { t } = usePageTranslation("payment_confirmation_page");

  const loadPayment = useCallback(async () => {
    if (!searchParameters.get("sessionId")) {
      navigate("/");
      return;
    }

    setLoading(true);

    try {
      await paymentService.getConfirmation(searchParameters.get("sessionId"));
      setPaymentStatus(true);
      setTimeout(() => {
        window.location.href = "/dispositives";
      }, 2000);
    } catch (error) {
      toast.error(apiErrorHandler(error));
      navigate("/");
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadPayment();
  }, [loadPayment]);

  return (
    <div>
      <div className="flex items-center text-2xl font-bold">
        <h1>{t("payment_confirmation")}</h1>
      </div>
      <div className="font-medium text-center shadow-lg p-5 bg-gray-700 rounded-md mt-10">
        <div className="flex flex-col justify-center items-center gap-2">
          <h2 className="text-lg font-medium uppercase">
            {t("confirming_payment")}
          </h2>
          {loading && <ClipLoader size="20" color="#36d7b7" />}
          {paymentStatus && (
            <div className="flex justify-center items-center">
              <i className="text-2xl ri-checkbox-circle-line mr-2 text-green-500" />
              {t("confirmed_payment_text")}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
