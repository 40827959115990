import React from "react";
import Logo from "../../assets/logo.png";
import OnOffLogo from "../../assets/onofflogo.png";
import AlexaLogo from "../../assets/alexa-logo.png";
import { translate } from "../../i18n/translate";

export default function AuthContainer({ children }) {
  return (
    <div className="h-screen">
      <div className="lg:grid lg:grid-cols-3 w-screen min-h-[calc(100vh-40px)] lg:h-[calc(100vh-40px)]">
        <div className="h-0 overflow-hidden lg:h-full flex items-center justify-center bg-gray-400">
          <div className="w-full p-10">{children}</div>
        </div>
        <div className="col-span-2 ">
          <div className="flex flex-col justify-center items-center h-full p-10">
            <div className="flex gap-2 justify-center">
              <img src={AlexaLogo} alt="logo" className="w-[200px] ml-[-30px] mb-10"></img>
              <img src={OnOffLogo} alt="logo" className="w-[125px] mb-10"></img>
            </div>
            <h1 className="font-medium text-3xl mb-5">WOL CMD</h1>
            <div className="mb-4 lg:mb-0 lg:hidden lg:overflow-hidden">
              {children}
            </div>
            <p className="text-center mb-2">
              {translate("generic.welcome_text_1")}
            </p>
            <p className="text-center mb-1 lg:px-20">
              {translate("generic.welcome_text_2")}
            </p>
            <p className="text-center lg:px-20">
              {translate("generic.welcome_text_3")}
            </p>
          </div>
        </div>
      </div>
      <div className="w-full h-[40px] bg-gray-300 flex justify-center px-5 items-center">
        <span className="text-gray-600">
          <b>Laera Software</b>
        </span>
        <img className="w-[15px] ml-2" src={Logo} alt="logo" />
        <span className="text-gray-600 ml-5">
          2017-{new Date().getFullYear()}
        </span>
      </div>
    </div>
  );
}
