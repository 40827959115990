import classNames from "classnames";
import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useRef } from "react";

export const PosFixInput = React.forwardRef(({ posFix, ...props }, ref) => {
  const handleBlur = () => {
    console.log("blur");
    if (props.value && !props.value.trimEnd().endsWith(posFix)) {
      props.onChange(props.value + " " + posFix);
    }
  };

  const handleFocus = () => {
    if (props.value && props.value.trimEnd().endsWith(posFix)) {
      props.onChange(props.value.replace(" " + posFix, ""));
    }
  };

  const lastPosFixRef = useRef(posFix);

  const handlePosFixChange = useCallback((currentValue, currentPosFix) => {
    if (currentValue && currentPosFix) {
      if (currentValue.trimEnd().endsWith(lastPosFixRef.current)) {
        props.onChange(
          currentValue.replace(lastPosFixRef.current, currentPosFix)
        );
      } else {
        props.onChange(currentValue + " " + currentPosFix);
      }
      lastPosFixRef.current = currentPosFix;
    }
  }, []);

  const debounceRef = useRef(debounce(handlePosFixChange, 200));

  useEffect(() => {
    debounceRef.current(props.value, posFix);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posFix]);

  return (
    <input
      {...props}
      ref={ref}
      onFocus={handleFocus}
      onBlur={() => {
        handleBlur();
        props.onBlur();
      }}
      className={classNames(
        "input input-primary placeholder-gray-600 w-full",
        props.className
      )}
    />
  );
});
